@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.container {
  background-color: black;
  max-width: calc(80rem + 4rem);
  margin: 0 auto;

  .containerTechnologie {
    .techIcons {
      margin: 0;
      padding: 0;
      padding-top: 4rem;
      padding-bottom: 4rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .box {
        width: 14rem;
        position: relative;
        height: 14rem;
        border: 1px solid $accent;
        z-index: 0;
        transition: all 0.3s ease;
        opacity: 1;
        margin-right: -1px;

        @include small {
          width: 8rem;
          height: 8rem;
        }

        &:hover {
          z-index: 10;
        }

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          background: black;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          opacity: 1;
        }
        &:before {
          width: calc(100% + 4px);
          height: 100%;
          z-index: 1;
          transition: height 1s ease, opacity 0.8s ease;
        }

        &:after {
          height: calc(100% + 4px);
          width: 100%;
          z-index: 1;
          transition: width 1s ease, opacity 0.8s ease;
        }

        &:hover,
        &:focus {
          &:before {
            transition: height 0.2s ease, opacity 0.3s ease;
            height: 85%;
            opacity: 0.7;
          }

          &:after {
            transition: width 0.2s ease, opacity 0.3s ease;
            width: 85%;
            opacity: 0.8;
          }

          .boxImage {
            opacity: 0.3;
            width: 80%;
          }

          .boxTitle {
            opacity: 1;
          }
        }

        .boxImage {
          width: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.5s ease;
          z-index: 2;
        }

        .boxTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          text-align: center;
          transition: all 0.5s ease;
          z-index: 2;
          color: $white;
          font-size: $text-lg;
          font-weight: $bold;
          opacity: 0;
          background-color: transparentize($accent, 0.95);
        }
      }
    }
  }
}
