@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 10rem 2rem;

  @include medium {
    padding: 0 2rem;
    padding-top: 10rem;
  }
  @include small {
    padding: 0 1rem;
    padding-top: 8rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 -1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 80rem;
  }
}
