@import '../../sass/abstracts/mixins.scss';
@import '../../sass/abstracts/variables.scss';

.overlay {
    .top {
        height: 100vh;
        position: absolute;
        width: 100%;
        z-index: 8;

        .overlayTop {
            position: absolute;
            height: 100%;
            width: 33.333vw;
            background-color: $white;
            bottom: 0;
            left: 0;
            right: 0;
            @include medium {
                width: 50vw;
            }
            @include small {
                width: 100vw;
            }

            &:nth-child(2) {
                left: 33.333%;
                @include medium {
                    left: 50%;
                    width: 50vw;
                }
                @include small {
                    display: none;
                }
            }

            &:nth-child(3) {
                left: 66.666%;
                @include medium {
                    display: none;
                }
            }
        }
    }
}