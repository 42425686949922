@import '../sass/abstracts/variables.scss';
@import '../sass/abstracts/mixins.scss';

.main {
    color: $white;
    height: 100%;
    min-height: 100vh;
    background-color: $black;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 80rem;
        margin: 0 auto;
        padding: 0 2rem;
        padding-top: 10rem;

        h1 {
            width: 100%;
            margin-bottom: 4rem;

            span {
                font-size: $text-m;
                font-weight: $light;
            }
        }

        form {
            width: 50%;
            margin-bottom: 5rem;

            @include medium {
                width: 100%;
            }
    
            div {
                position: relative;
                
                textarea {
                    padding: 0.5rem;
                }
    
                input, textarea {
                    width: calc(100% - 0.5rem);
                    margin-bottom: 3rem;
                    padding: 0.5rem 0;
                    background: none;
                    border: none;
                    border-bottom: 1px solid $white;
                    outline: none;
                    transition: all 0.2s ease;
                    color: $white;
                }
    
                label {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    pointer-events: none;
                    transition: all 0.2s ease;
                }
    
                .inputBorder {
                    width: 0;
                    background: $accent;
                    height: 0.2rem;
                    display: block;
                    transform: translateY(-3.1rem);
                    transition: all 0.2s ease;
                }
    
                input:focus ~ label,
                textarea:focus ~ label {
                    top: -12px;
                    font-size: 0.8rem;
                    font-weight: $bold;
                    color: $accent;
                }
    
                input:valid ~ label,
                textarea:valid ~ label {
                    top: -12px;
                    font-size: 0.8rem;
                    font-weight: $bold;
                }
    
                input:focus ~ span, 
                input:valid ~ span,
                textarea:focus ~ span, 
                textarea:valid ~ span {
                    width: calc(100% - 0.5rem);
                }

                textarea:focus, 
                textarea:valid {
                    background: rgba(128, 128, 128, 0.205);
                    padding: 0.5rem;
                    width: calc(100% - 1.5rem);
                    margin-top: 0.5rem;
                    border-bottom: 2px solid $accent;
                }
                
            }
    
            .formButton {
                margin-top: 1rem;
                width: 100%;
                font-size: $text-base;
                background-color: $accent;
                border: none;
                padding: 0.5rem;
                box-shadow: 0 0 5px $accent, 0 0 15px transparentize($accent, 0.5);
                cursor: pointer;
                text-decoration: none;
                color: $black;
                font-weight: $bold;
                transition: all 0.2s ease;
                border: 2px solid $accent;

                &:hover {
                    box-shadow:none;
                    background: none;
                    color: $white;
                    border: 2px solid $accent;
                }
            }
    
        }

        .contactDetails {
            width: 25%;

            @include large {
                width: 40%;
            }
            @include medium {
                width: 100%;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    margin-bottom: 2rem;
                    
                    a {
                        display: flex;
                        align-items: center;
                        color: $white;
                        text-decoration: none;
                        margin-bottom: 0.5rem;
                        transition: all 0.3s ease;

                        &:hover {
                            color: $accent;
                            transform: translateY(-0.3rem);
                            font-size: $text-m;

                            .icon {
                                font-size: $text-xlg;
                            }
                        }

                        .icon {
                            font-size: $text-lg;
                            transition: all 0.3s ease;
                        }

                        .info {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }


}