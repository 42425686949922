@import "../sass/abstracts/variables.scss";
@import "../sass/abstracts/mixins.scss";

.main {
  height: 100%;
  min-height: 100vh;
  background-color: $white;

  .contentDetail {
    opacity: 0;
    background-color: black;
  }
}
