@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.back {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 13rem;

  @include medium {
    padding: 0 2rem;
    padding-top: 11rem;
  }

  @include small {
    padding: 0 1rem;
    padding-top: 8rem;
  }

  a {
    color: white;
    @media (min-width: $large-width) {
      margin-left: -2rem;
    }
  }
}

.containerInfo {
  color: $white;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: black;

  @include medium {
    padding: 0 2rem;
    padding-top: 3rem;
  }
  @include small {
    padding: 0 1rem;
    padding-top: 3rem;
  }

  .image_mockup {
    background: $white;
    margin: 0 -2rem;

    @include medium {
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 2rem 1rem;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .briefing {
    span {
      padding: 3rem 2rem;
      display: block;
      margin-left: -2rem;
      margin-right: -2rem;
      margin-top: 3rem;
      background-color: $white;
      color: $black;
      font-size: $text-lg;
      line-height: 1.2;
      font-weight: $bold;

      @include medium {
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 2rem 1rem;
        width: 100%;
      }

      @include small {
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 2rem 1rem;
        width: 100%;
        font-size: $text-m;
      }
    }
  }

  .hero_media {
    width: calc(50% - 4rem);

    @include medium {
      width: 100%;
    }

    @media (min-width: $large-width) {
      margin-left: -2rem;
    }
  }

  .overview {
    width: 50%;

    p {
      line-height: 1.5;
    }

    .flexContainer {
      display: flex;

      a {
        color: $black;
        text-decoration: none;
        transition: all 0.4s ease;
        display: block;
        margin: 1rem 0;
        margin-right: 1rem;
        background-color: transparentize($accent, 0.8);
        border-radius: 0.2rem;
        padding: 0.3rem;
        display: flex;
        align-items: center;
        border: 1px solid $accent;

        .icon {
          font-size: $text-lg;
          margin-right: 0.5rem;
          color: $accent;
        }

        span {
          color: $accent;
          padding-top: 0.2rem;
          margin-right: 0.5rem;
        }

        &:hover {
          color: $accent;
          background-color: $accent;
          border: 1px solid $black;

          .icon,
          span {
            color: $black;
          }
        }
      }
    }

    @include medium {
      width: 100%;
    }
  }

  .producedBy {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 2rem 2rem;
    background-color: $white;
    margin-left: -2rem;
    margin-right: -2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    ul {
      margin: 0;
      padding: 0;
    }

    @include medium {
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 2rem 1rem;
      width: 100%;
    }

    @include small {
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 2rem 1rem;
      width: 100%;
    }

    h2 {
      text-align: center;
      color: $black;
    }

    a {
      color: $black;
      display: block;
      margin: 0.75rem 0;
      transition: all 0.3s ease;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
