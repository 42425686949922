@import '../../sass/abstracts/mixins.scss';
@import '../../sass/abstracts/variables.scss';

.menu {
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10;

    .menuBg {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: $white;
        
        z-index: -1;
    }

    .menuOverlay {
        position: relative;
        height: 100%;
        overflow: hidden;

        .navWrapper {
            position: relative;
            padding: 0 3rem;

            .navLinks {
                position: relative;
                top: 12.5rem;
                max-width: 80rem;
                margin: 0 auto;

                nav {
                    display: block;

                    ul {
                        padding: 0;
                        margin: 0;

                        li {
                            list-style: none;
                            font-size: $text-xxxlg;
                            font-weight: $bold;
                            height: 8.5rem;
                            overflow: hidden;
                            position: relative;
                            @include small {
                                font-size: $text-xlg;
                                height: 5rem;
                            }

                            a {
                                position: absolute;
                                color: $black;
                                text-decoration: none;
                                transition: all 0.2s ease;

                                &:hover {
                                    color: $secondaryAccent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}