@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.main {
  height: 100%;
  min-height: 100vh;
  background-color: $black;
  @include small {
    padding-bottom: 2rem;
  }

  .container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 2rem;
    padding-top: 9rem;
    @include medium {
      padding: 0 2rem;
      padding-top: 6rem;
    }
    @include small {
      padding: 0 1rem;
      padding-top: 5rem;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0rem;
      @include medium {
        margin-top: 2rem;
      }

      .intro {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-width: 50%;
        width: 50%;
        @include medium {
          width: 100%;
        }
        @include small {
          margin-top: 4em;
        }

        .content {
          font-size: $text-xxlg;
          line-height: 4.2rem;
          font-weight: $bold;
          z-index: 10;
          color: $white;
          mix-blend-mode: difference;
          @include medium {
            font-size: $text-xlg;
            line-height: 3rem;
          }
          @include small {
            font-size: $text-lg;
            line-height: 2.2rem;
          }

          .mask {
            margin-bottom: 0.5rem;
            height: 3.5rem;
            position: relative;
            overflow: hidden;
            @include medium {
              height: 3rem;
            }
            @include small {
              height: 2rem;
            }
            span {
              position: absolute;
            }

            p {
              display: inline;
              color: $accent;
            }
          }
        }
      }

      .avatar {
        width: 50%;
        min-width: 15rem;
        @include medium {
          order: -1;
          margin: 0 auto;
        }
      }
    }

    .description {
      position: relative;
      z-index: 2;

      span {
        margin-top: 2rem;
        display: block;
        font-size: $text-m;
        color: $white;
        line-height: 1.5;
        @include small {
          font-size: $text-base;
        }
      }

      .CTA {
        margin-top: 4rem;
        .btn {
          text-decoration: none;
          color: $white;
          padding: 1rem 2.5rem;
          position: relative;

          &::after,
          &::before {
            border: 2px solid $white;
            content: "";
            position: absolute;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            left: 0;
            bottom: 0;
            transition: transform 0.3s ease;
          }

          &:hover {
            color: $accent;
          }

          &:hover::after {
            transform: translate(-5px, -5px);
          }
          &:hover::before {
            transform: translate(5px, 5px);
          }
        }
      }
    }
  }
}
