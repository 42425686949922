@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.card {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: $white;
  padding: 5rem 0 0;
  max-width: 100%;
  width: 30ch;
  flex-grow: 1;
  position: relative;
  transition: transform 0.5s ease;
  overflow: hidden;

  @media (hover) {
    &:hover,
    &:focus-within {
      transform: scale(1.05);

      .content .title::before {
        transform: scaleX(1);
      }

      .content .title::after {
        height: calc(100% + 0.75rem);
      }

      .content .title {
        color: $black;
      }

      .content {
        transform: translateY(0);
        transition-delay: 0.5s;
      }

      .content > *:not(.title) {
        opacity: 1;
        transition-delay: 0.6s;
      }
    }

    &:focus-within {
      .content {
        transition-duration: 0s;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 1.5rem;
    padding-top: 0;
    padding-bottom: 2rem;
    background-color: transparentize($black, 0.3);
    backdrop-filter: blur(3px);

    p {
      line-height: 1.3;
    }

    @media (min-width: 694px) {
      height: 14rem;
    }

    @media (hover) {
      transform: translateY(75%);
      transition: transform 0.5s ease;

      & > *:not(.title) {
        opacity: 0;
        transition: opacity 0.5s linear;
      }

      .title {
        position: relative;
        width: max-content;
        max-width: 100%;
        transition: all 0.5s ease;
        transition-delay: 0.5s;

        &::before {
          content: "";
          position: absolute;
          height: 4px;
          border-radius: 1rem;
          background-color: darken($accent, 25%);
          width: calc(100% + 2rem);
          left: -1.5rem;
          bottom: -0.25rem;
          transform: scaleX(0);
          transform-origin: left;
          transition: all 0.5s ease;
        }

        &::after {
          z-index: -1;
          content: "";
          position: absolute;
          height: 2px;
          background-color: transparentize($accent, 0.4);
          width: calc(100% + 2rem);
          left: -1.5rem;
          bottom: -0.25rem;
          transition: all 0.5s ease;
          transition-delay: 0.5s;
        }
      }

      p {
        line-height: 1.5;
      }
    }

    .button {
      text-decoration: none;
      color: $black;
      background-color: white;
      padding: 0.75rem 1rem;
      position: relative;
      text-align: center;

      &::after,
      &::before {
        border: 2px solid $white;
        content: "";
        position: absolute;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        left: 0;
        bottom: 0;
        transition: transform 0.3s ease;
      }

      &:hover {
        color: white;
        background-color: #04ded078;
      }

      &:hover::after {
        transform: translate(-5px, -5px);
      }

      &:hover::before {
        transform: translate(5px, 5px);
      }
    }
  }
}
