@import "./variables.scss";
// Media queries

@mixin small {
  @media (max-width: #{$small-width}) {
    @content;
  }
}

@mixin medium {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

@mixin extra-large {
  @media (max-width: #{$extra-large-width}) {
    @content;
  }
}
