@import '../../sass/abstracts/mixins.scss';
@import '../../sass/abstracts/variables.scss';

.header {
    position: fixed;
    width: 100%;
    height: 8rem;
    z-index: 40;

    @include small { 
        height: 6rem;
    }

    .border {
        height: 1px;
        background-color: transparentize($accent, $amount: 0.8) ;
        width: 0%;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .flex_container {
        height: 100%;
        max-width: 80rem;
        margin: 0 auto;
        padding: 0 2rem;

        @include medium {
            padding: 0 2rem;
        }
        @include small {
            padding: 0 1rem;
        }

        .vertical_center {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .logo {
                transform: translateX(-100%);
                opacity: 0;
                a {
                    font-size: $text-m;
                    letter-spacing: 0.3rem;
                    color: $white;
                    text-decoration: none;
                    font-weight: $bold;
                    text-transform: uppercase;

                    &::after {
                        content: ".";
                        color: $accent;
                        font-size: $text-lg;
                    }
                }
            }
        
            .nav {
                width: 2.3rem;
                cursor: pointer;
                background: none;
                border: none;
                position: relative;
                z-index: 60;
                mix-blend-mode: difference;
                span {
                    margin-bottom: 0.5rem;
                    background-color: $white;
                    height: 0.15rem;
                    border-radius: 0.5rem;
                    width: 100%;
                    display: block;
                }
            }
        }


    }
}
