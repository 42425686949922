// width 
$small-width: 425px;
$medium-width: 768px;
$large-width: 1366px;
$extra-large-width: 1920px;

// colors 
$black: #0b0c10;
$white: #ffffff;

$accent: #66fcf1;
$secondaryAccent: #99030E;


// font size
$text-base: 1rem;
$text-m: 1.3rem;
$text-lg: 1.7rem;
$text-xlg: 2.5rem;
$text-xxlg: 3rem;
$text-xxxlg: 5.5rem;


$text-btn-l: 1.6rem;
$text-btn-m: 1.4rem;
$text-btn-s: 1.1rem;

// font-weight
$light: 300;
$normal: 400;
$bold: 700;
